.renewal-email-title-con {
  background: white;
  padding: 10px;
  /* border-radius: 20px; */
  border: 1px solid lightgray;
}

.position-test-renewal-email-btn {
  bottom: -57px;
  left: 0;
  position: absolute;
  z-index: 1;
  background-color: white;
  padding: 17px;
  border: solid 1px lightgray;
}

.country-addresses-con {
  position: absolute;
  left: 0s;
  left: 8px;
  top: 160px;
  z-index: 1;
}

.country-addresses-con span {
  color: #152c3a;
  padding: 3px 6px;
  border-radius: 6px;
  background-color: #ffb109;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
