.session-con {
  width: 400px;
  margin: 60px auto;
}

.login-top-blue {
  background-color: #336b88;
  border-color: #336b88;
  border-bottom: #fbae07 1px solid;
  color: white;
  padding: 10px 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: right;
  font-weight: bold;
}

.login-top-blue span {
  float: left;
}

.login-wrapper {
  background: white;
  min-height: 240px;
  padding: 15px;
  position: relative;
  box-shadow: 0 30px 40px 0 rgb(16 36 94 / 20%);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.session-con a {
  color: #336b88;
}

.session-con button {
  background-color: #336b88;
  color: white;
}

.simulate-link {
  color: #336b88;
  text-decoration: underline;
  cursor: pointer;
}

.resend-conf-con {
  position: absolute;
  top: 265px;
  box-shadow: 0 30px 40px 0 rgb(16 36 94 / 20%);
  border-radius: 9px;
}

.resend-conf-con > div {
  border-radius: 9px !important;
}

.forgot-pass-link {
  position: absolute;
  left: 5px;
  bottom: -25px;
}

.align-job-type-icon {
  vertical-align: 5.5px;
}

.prof-con {
  position: absolute;
  right: 30px;
  color: lightgray;
  font-size: 18px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  top: 12px;
}

@media only screen and (max-width: 1420px) {
  .align-job-type-icon {
    margin-right: 40px;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 465px) {
  .session-con {
    width: 90%;
  }

  .session-con .width100-sm {
    width: 100% !important;
  }

  .resend-conf-con {
    max-width: 91%;
    offset: bottom;
  }
}
